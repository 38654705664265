<template>
  <div>
    <a-row>
      <div class="title custom-flex">
        <div class="icon" />
        <span>订单数据统计</span>
      </div>
    </a-row>
    <a-row>
      <div class="going-order custom-flex custom-items-center">
        <div>
          <a-icon type="reconciliation" :style="{fontSize:'80px',color:'#ffffff'}" />
        </div>
        <div class="content">
          <div>进行中订单数量</div>
          <div class="count">{{ totalOrdersNumberInProgress }}</div>
        </div>
      </div>
    </a-row>
    <a-row>
      <div class="order-total-count custom-flex custom-items-center">
        <div>
          <a-icon type="profile" :style="{fontSize:'80px',color:'#ffffff'}" />
        </div>
        <div class="content">
          <div>订单总数</div>
          <div class="count">{{ totalOrdersNumber }}</div>
        </div>
      </div>
    </a-row>
  </div>
</template>

<script>
import { findTenantHomePageOrderStatistics } from '@/api/home'
export default {
  name: 'OrderDataStatistics',
  data() {
    return {
      totalOrdersNumber: 0,
      totalOrdersNumberInProgress: 0
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findTenantHomePageOrderStatistics().then(res => {
        if (res.code === 0) {
          this.totalOrdersNumberInProgress = res.data.total_number_of_orders_in_progress
          this.totalOrdersNumber = res.data.total_number_of_orders
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  font-size: 14px;

  .icon {
    margin: 3px 3px 0 0;
    width: 3px;
    height: 15px;
    background-color: @primary-color;
  }
}

.going-order {
  border-radius: 10px;
  background: linear-gradient(to top right, #1ac0cb, #14cd6e);
  margin: 22px 0 20px 0;
  padding: 20px 0 20px 50px;
}

.content {
  font-size: 20px;
  color: #ffffff;
  margin-left: 20px;

  .count {
    font-size: 40px;
  }
}

.order-total-count {
  border-radius: 10px;
  padding: 20px 0 20px 50px;
  background: linear-gradient(to top right, #c33bff, #a060ff);
}
</style>
